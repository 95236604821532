<template>
  <div class="container">
    <h1>{{ $t("menu.clients") }}</h1>
    <div class="client-list">
      <p>
        {{ $t("clients.intro") }}
      </p>

      <div class="columns">
        <div class="category mb-4">
          <h3>{{ $t("clients.arts-and-culture") }}</h3>
          <ul>
            <li>Architecturae et Amicitia, Amsterdam</li>
            <li>Carnegie&nbsp;Hall, New&nbsp;York</li>
            <li>Centraal Museum Utrecht</li>
            <li>Christie’s, New&nbsp;York</li>
            <li>Cultuur &amp; Ondernemen, Amsterdam</li>
            <li>Danny Clinch Photography, New&nbsp;York</li>
            <li>Gate&nbsp;Foundation, Amsterdam</li>
            <li>Japan Nederland Instituut, Tokyo</li>
            <li>Joods Historisch Museum, Amsterdam</li>
            <li>Museum De&nbsp;Lakenhal, Leiden</li>
            <li>Museum voor Communicatie, Den&nbsp;Haag</li>
            <li>Nederlands Architectuur Instituut Nai, Rotterdam</li>
            <li>Nederlandse Vakgroep Keramisten, Zeewolde</li>
            <li>Stadsschouwburg Amsterdam</li>
            <li>Tempi &amp; Wolf, Amsterdam</li>
            <li>Van&nbsp;Lamsweerde, Amsterdam/New&nbsp;York</li>
            <li>Vereniging Leliman, Rotterdam</li>
            <li>Workplace Pride, Amsterdam</li>
          </ul>
        </div>

        <div class="category mb-4">
          <h3>{{ $t("clients.education-and-management") }}</h3>
          <ul>
            <li>Hogeschool Utrecht</li>
            <li>ING Business School, Amsterdam</li>
            <li>Jazz Studies at Columbia, Columbia University, New&nbsp;York</li>
            <li>KEI Kenniscentrum Stedelijke Vernieuwing, Rotterdam</li>
            <li>Koninklijke Academie van Beeldende Kunsten, Den&nbsp;Haag</li>
            <li>KPMG International, Los&nbsp;Angeles</li>
            <li>KPMG Knowledge Management, Amstelveen</li>
            <li>Nolan Norton Institute, Utrecht</li>
            <li>New York University, NYU&nbsp;SCPS, New&nbsp;York</li>
            <li>Southern Poverty Law Center, Montgomery</li>
            <li>Universiteit van Amsterdam</li>
          </ul>
        </div>

        <div class="category mb-4">
          <h3>{{ $t("clients.telecom") }}</h3>
          <ul>
            <li>Avaya, Basking Ridge</li>
            <li>KPN Nederland, Den&nbsp;Haag</li>
            <li>Mediaone, Atlanta, GA</li>
            <li>TPG Post Nederland</li>
            <li>Verizon Communications, New&nbsp;York</li>
          </ul>
        </div>

        <div class="category mb-4">
          <h3>{{ $t("clients.finance-and-insurance") }}</h3>
          <ul>
            <li>Bank of America Merrill Lynch, New&nbsp;York</li>
            <li>Banque de France, Paris</li>
            <li>Chase Manhattan Bank, Louisville</li>
            <li>De Nederlandsche Bank, Amsterdam</li>
            <li>Generali Verzekeringsgroep NV, Amsterdam</li>
            <li>Guardian Life Insurance, New&nbsp;York</li>
            <li>ING Nederland, Amsterdam</li>
            <li>Instinet, New York</li>
            <li>J.P.&nbsp;Morgan, New&nbsp;York</li>
            <li>Manulife Financial, Toronto</li>
            <li>Bank of America Merrill Lynch, New&nbsp;York</li>
            <li>Nationale Nederlanden, Den&nbsp;Haag</li>
            <li>Netherlands Caribbean Bank, Curaçao, Nederlandse&nbsp;Antillen</li>
            <li>Allianz Global Investors, Newport&nbsp;Beach</li>
            <li>Worldbank, Washington&nbsp;D.C.</li>
          </ul>
        </div>

        <div class="category mb-4">
          <h3>{{ $t("clients.healthcare-and-wellness") }}</h3>
          <ul>
            <li>Eli Lilly, Indianapolis</li>
            <li>Genentech, San&nbsp;Francisco</li>
            <li>GlaxoSmithKline, Philadelphia</li>
            <li>Huisartsenposten Amsterdam</li>
            <li>Hythiam, Los&nbsp;Angeles</li>
            <li>MedManage Systems, Bothell</li>
            <li>NY Presbyterian Hospital, New&nbsp;York</li>
            <li>Novartis, East&nbsp;Hanover</li>
            <li>Novo Nordisk, Princeton</li>
            <li>Pfizer, New&nbsp;York</li>
            <li>Quest Diagnostics, Madison</li>
            <li>Shire, Wayne</li>
            <li>TAP Pharmaceuticals, Deerfield</li>
          </ul>
        </div>

        <div class="category mb-4">
          <h3>{{ $t("clients.architecture-and-engineering") }}</h3>
          <ul>
            <li>B&amp;W Interieurs en Decoratie, Amsterdam</li>
            <li>Bodem voor Ruimte, Hoofddorp</li>
            <li>Claus en Kaan Architecten, Amsterdam/Rotterdam</li>
            <li>Duintjer&nbsp;Architecten, Amsterdam</li>
            <li>Element, Amsterdam/Haarlem</li>
            <li>Hans van Heeswijk Architecten, Amsterdam</li>
            <li>ICAMT</li>
            <li>Leven met Water, Gouda</li>
            <li>NOK Netwerk Omgevingskwaliteit, Gouda</li>
            <li>OLS Ondergrond Logistiek Systeem, Schiphol</li>
            <li>TNO Milieu Energie en Procesinnovatie, Apeldoorn</li>
            <li>ToornendPartners, Haarlem</li>
          </ul>
        </div>

        <div class="category mb-4">
          <h3>{{ $t("clients.industry-and-consumer-goods") }}</h3>
          <ul>
            <li>Aluminium Building Products, Taipei</li>
            <li>3Com, Marlborough</li>
            <li>Casio Electronics, New&nbsp;York</li>
            <li>Cemex, Mexico</li>
            <li>Ford Motor Company, Dearborn</li>
            <li>Hyundai Cars, Sassenheim</li>
            <li>Nike, Beaverton</li>
            <li>Sacksioni, Amsterdam</li>
          </ul>
        </div>

        <div class="category mb-4">
          <h3>{{ $t("clients.media-and-publishing") }}</h3>
          <ul>
            <li>010 Uitgevers, Rotterdam</li>
            <li>Academy Editions, Londen</li>
            <li>Addison-Wesley Publishers, Amsterdam</li>
            <li>Black Entertainment Television, Washington&nbsp;D.C.</li>
            <li>Beukers Scholma, Haarlem</li>
            <li>Codefab, New&nbsp;York</li>
            <li>De Baak, Management Centrum VNO-NCW, Noordwijk</li>
            <li>DivMod, New&nbsp;York</li>
            <li>Elektra Records New&nbsp;York</li>
            <li>Encyclopedia Britannica, Chicago</li>
            <li>Greater Than One, New&nbsp;York</li>
            <li>Het Firmament, Amsterdam</li>
            <li>InStyle Magazine, New&nbsp;York</li>
            <li>Muziekjournaal Entr'acte, Amsterdam</li>
            <li>Nijgh Versluys, Baarn</li>
            <li>One Club, New York</li>
            <li>Pearson Education, Amsterdam</li>
            <li>Razorfish, Inc., Seattle</li>
            <li>RealSimple, New York</li>
            <li>Soft Cell Belgium NV, Antwerpen</li>
            <li>VNU Business Publications, Amsterdam</li>
          </ul>
        </div>

        <div class="category mb-4">
          <h3>{{ $t("clients.fashion-and-beauty") }}</h3>
          <ul>
            <li>Armani, Milano</li>
            <li>Bourjois Paris Cosmetics, Paris</li>
            <li>Cosmopolitan Cosmetics, Brooklyn</li>
            <li>Estée Lauder, New&nbsp;York</li>
            <li>Procter &amp; Gamble, Cincinnati</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>
